import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import "./StepContactDetails.scss";
import InputComp from "@components/InputComp/InputComp";
import Mixpanel from "@utils/mixpanel";
import Button from "@components/Button/Button";
import { BUTTON_LABELS, STEPS } from "@constants/home.constants";
import PhoneInput from "@components/PhoneInput/PhoneInput";
import countryCodes from "@assets/json/country-codes.json";
import {
  ContactDetails,
  initialState,
  resetContactDetails,
  setContactDetails
} from "@store/valuation/valuationSlice";
import { useAppDispatch, useAppSelector, useWindowDimensions } from "@hooks";
import {
  CONTACT_DETAILS_FIELDS,
  CONTACT_DETAILS_PLACEHOLDERS,
  CONTACT_DETAILS_TITLE,
  CONTACT_TYPES,
  CONTACT_TYPE_LABEL,
  EMAIL_INVALID_ERROR,
  EMAIL_REQUIRED_ERROR,
  INVALID_PHONE_ERROR,
  PHONE_REQUIRED_ERROR
} from "@constants/home.contactDetails.constants";
import { JOURNEY_NAME, NEXT_BUTTON_CLICK_TYPE, PAGE_VARIANT } from "@utils/mixpanel/constants";
import { MixpanelEventNames } from "@utils/mixpanel/events";
import { isIOS } from "react-device-detect";
import {
  generateValuationPayload,
  validateContactDetailsUserDropOffHSPayload
} from "@utils/helpers/data.helper";
import apiService from "@utils/apiService";
import { ReportPayload } from "@store/report/types";
import { debounce } from "@utils/helpers";
import { populateStateFromQueryStepsData } from "@utils/helpers/populate-state-from-query.helper";
import { addPreviousStep, removeLastStep } from "@utils/helpers/add-query-step.helper";

const validationSchema = Yup.object().shape({
  [CONTACT_DETAILS_FIELDS.CONTACT_TYPE]: Yup.string().required().label("Contact Type"),
  [CONTACT_DETAILS_FIELDS.FIRST_NAME]: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "First Name is invalid")
    .required()
    .label("First Name"),
  [CONTACT_DETAILS_FIELDS.SURNAME]: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Surname is invalid")
    .required()
    .label("Surname")
});

export interface MixpanelContactDetailsInterface {
  "Journey Name": string;
  "Button Pressed": string;
  "Page Variant": string;
  Email: string;
  "First Name": string;
  "Last Name": string;
  "Phone Number": string;
}

const StepContactDetails = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const invalidPhoneRef = useRef(true);
  const initialContactState =
    useAppSelector((state) => state.valuation.contactDetails) || initialState.contactDetails;
  const [phoneError, setPhoneError] = useState(
    initialContactState.phone ? "" : PHONE_REQUIRED_ERROR
  );
  const [emailError, setEmailError] = useState(
    initialContactState.email ? "" : EMAIL_REQUIRED_ERROR
  );

  const navigate = useNavigate();

  useEffect(() => {
    populateStateFromQueryStepsData(searchParams, dispatch, navigate);
  }, []);

  const phoneRef = useRef("");
  const formData = useAppSelector((state) => state.valuation);

  const { isMobile } = useWindowDimensions();

  const getMixpanelData = (values: ContactDetails, buttonPressed: string) => ({
    "Journey Name": JOURNEY_NAME.owner,
    "Button Pressed": buttonPressed,
    "Page Variant": PAGE_VARIANT.A,
    Email: values.email,
    "First Name": values.firstName,
    "Last Name": values.surname,
    "Phone Number": `+${values.countryCode}${values.phone}`
  });

  const getMixpanelDataInvalidPhone = (phone: string) => ({
    Error: true,
    "Phone Number": phone,
    "Error Message": "The phone number is invalid",
    "Page Variant": PAGE_VARIANT.A,
    "Journey Name": JOURNEY_NAME.owner
  });

  const handleBack = (values: ContactDetails) => {
    dispatch(resetContactDetails());
    searchParams.set("previousSteps", removeLastStep(STEPS.CONTACT_DETAILS, searchParams));
    searchParams.delete(STEPS.CONTACT_DETAILS);
    searchParams.set("step", STEPS.BEDROOMS);
    setSearchParams(searchParams);
    Mixpanel(
      MixpanelEventNames.pl_valuation_enter_user_details,
      getMixpanelData(values, NEXT_BUTTON_CLICK_TYPE.back)
    );
  };

  const handleNext = () => {
    searchParams.set("step", STEPS.PROPERTY_AVAILABILITY);
    setSearchParams(searchParams);
  };

  let timeoutMixpanelEvent: any = null;

  const mixPanelDebounce = (phone: string, error: boolean) => {
    if (timeoutMixpanelEvent) {
      clearTimeout(timeoutMixpanelEvent);
    }
    if (!error) return;
    timeoutMixpanelEvent = setTimeout(() => {
      if (invalidPhoneRef.current) {
        Mixpanel(
          MixpanelEventNames.user_details_invalid_phone_number,
          getMixpanelDataInvalidPhone(phone)
        );
      }
    }, 20000);
  };

  let timeoutEvent: any = null;

  const validatePhoneDebounce = (phone: string) => {
    if (timeoutEvent) clearTimeout(timeoutEvent);
    timeoutEvent = setTimeout(async () => {
      if (phone) {
        mixPanelDebounce(phone, false);
        invalidPhoneRef.current = false;
        setPhoneError(INVALID_PHONE_ERROR);
        const phoneValidation = await apiService.get(
          `/v1/public/validate-phone?phoneNumber=${encodeURIComponent(phone)}`
        );
        // if phone number changed then return ;
        if (phone !== phoneRef.current) {
          return;
        }

        if (phoneValidation && phoneValidation.data.isMobileValid === true) {
          setPhoneError("");
          mixPanelDebounce(phone, false);
          invalidPhoneRef.current = false;
        } else {
          setPhoneError(INVALID_PHONE_ERROR);
          invalidPhoneRef.current = true;
          mixPanelDebounce(phone, true);
        }
      }
    }, 500);
  };

  const handlePhoneChange = (
    phone: any,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    phoneRef.current = phone.contactNo;
    setFieldValue(CONTACT_DETAILS_FIELDS.PHONE, phone.phone || "");
    setFieldValue(CONTACT_DETAILS_FIELDS.COUNTRY_CODE, phone.country.phonecode || 44);
    if (phone.phone && phone.phone.length >= 1 && phone.error) {
      setPhoneError(INVALID_PHONE_ERROR);
      mixPanelDebounce(phone.contactNo, true);
    } else if (phone.phone && phone.phone.length >= 1) {
      setPhoneError(INVALID_PHONE_ERROR);
      validatePhoneDebounce(phone.contactNo);
    } else {
      setPhoneError(PHONE_REQUIRED_ERROR);
    }
  };

  const validateEmail = debounce(async (email) => {
    if (email) {
      const emailValidation = await apiService.get(
        `/v1/public/validate-email?email=${encodeURIComponent(email)}`
      );
      if (emailValidation && emailValidation.data === true) return setEmailError("");
      return setEmailError(EMAIL_INVALID_ERROR);
    }
    return setEmailError(EMAIL_REQUIRED_ERROR);
  }, 500);

  const handleEmailChange = (
    value: string | null | undefined,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    setFieldValue(CONTACT_DETAILS_FIELDS.EMAIL, value || "");
    validateEmail(value);
  };

  useEffect(() => {
    if (isIOS) {
      document.getElementById("contact_details_form")?.scrollIntoView(true);
    } else if (isMobile) {
      document
        .getElementById("contact_details_form")
        ?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, []);

  const sendDataToHubSpot = async (values: ContactDetails) => {
    const hasUserDroppedOff = true;
    const payload: ReportPayload = generateValuationPayload(
      {
        ...formData,
        contactDetails: {
          ...values
        }
      },
      hasUserDroppedOff
    );

    if (validateContactDetailsUserDropOffHSPayload(payload)) {
      delete payload.property?.propertyAvailability;
      await apiService.post(`/v1/properties/valuation-lead`, { ...payload });
    }
  };

  return (
    <IonGrid className="step-contact-details" id="contact_details_form">
      <Formik
        initialValues={initialContactState}
        onSubmit={(values) => {
          const contactNo = values.phone.startsWith("0") ? values.phone.substring(1) : values.phone;
          const updatedValues = { ...values, phone: contactNo };
          dispatch(setContactDetails(updatedValues));
          searchParams.set("previousSteps", addPreviousStep(STEPS.CONTACT_DETAILS, searchParams));
          const encryptedContactDetails = btoa(JSON.stringify(updatedValues));
          searchParams.set(STEPS.CONTACT_DETAILS, encryptedContactDetails);
          setSearchParams(searchParams);
          handleNext();
          sendDataToHubSpot(updatedValues);
          Mixpanel(
            MixpanelEventNames.pl_valuation_enter_user_details,
            getMixpanelData(updatedValues, NEXT_BUTTON_CLICK_TYPE.manual)
          );
        }}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnMount
      >
        {({ values, errors, setFieldValue, touched, handleBlur, isValid, handleSubmit }) => (
          <IonGrid>
            <IonRow className="title-container">
              <IonCol>
                <IonText className="title-text">{CONTACT_DETAILS_TITLE}</IonText>
              </IonCol>
            </IonRow>

            <IonGrid className="input-container">
              <IonRow>
                <IonCol size="12" sizeMd="6" className="left_col">
                  <IonRow className="contact-type-label-wrapper">
                    <IonCol size="8" sizeMd="7" className="text_left">
                      <IonText className="contact-type-label">{CONTACT_TYPE_LABEL}</IonText>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
              <IonRow className="contact-type-buttons-row">
                {CONTACT_TYPES.map((contactType) => {
                  return (
                    <IonCol key={contactType.id} className="inputs-col" sizeMd="6" size="5.5">
                      <Button
                        classes={`form_button contact-type-button ${
                          values?.contactType === contactType.value ? "selectedButton" : ""
                        }`}
                        label={contactType.label}
                        onClick={() =>
                          setFieldValue(CONTACT_DETAILS_FIELDS.CONTACT_TYPE, contactType.value)
                        }
                      />
                    </IonCol>
                  );
                })}
              </IonRow>
              <IonRow>
                <IonCol className="inputs-col left_col" size="12" sizeMd="6">
                  <InputComp
                    classes="input-wrapper"
                    placeholder={CONTACT_DETAILS_PLACEHOLDERS.FIRST_NAME}
                    onBlur={handleBlur}
                    isMandatory={true}
                    type="text"
                    error={(touched.firstName && errors.firstName) || ""}
                    value={values?.firstName || ""}
                    name={CONTACT_DETAILS_FIELDS.FIRST_NAME}
                    onChange={(value) =>
                      setFieldValue(CONTACT_DETAILS_FIELDS.FIRST_NAME, value?.trim() || "")
                    }
                  />
                </IonCol>
                <IonCol className="inputs-col right_col" size="12" sizeMd="6">
                  <InputComp
                    classes="input-wrapper"
                    placeholder={CONTACT_DETAILS_PLACEHOLDERS.SURNAME}
                    name={CONTACT_DETAILS_FIELDS.SURNAME}
                    onBlur={handleBlur}
                    isMandatory={true}
                    type="text"
                    error={(touched.surname && errors.surname) || ""}
                    value={values?.surname || ""}
                    onChange={(value) =>
                      setFieldValue(CONTACT_DETAILS_FIELDS.SURNAME, value?.trim() || "")
                    }
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol className="inputs-col left_col" size="12" sizeMd="6">
                  <InputComp
                    classes="input-wrapper"
                    placeholder={CONTACT_DETAILS_PLACEHOLDERS.EMAIL}
                    isMandatory={true}
                    type="text"
                    onBlur={handleBlur}
                    name={CONTACT_DETAILS_FIELDS.EMAIL}
                    error={(touched.email && emailError) || ""}
                    value={values?.email || ""}
                    onChange={(value) => handleEmailChange(value, setFieldValue)}
                  />
                </IonCol>
                <IonCol className="inputs-col right_col" size="12" sizeMd="6">
                  <PhoneInput
                    classes="phone-input"
                    countryCodeValue={values?.countryCode || 44}
                    countryCodeList={countryCodes["country-codes"]}
                    value={values?.phone || ""}
                    isMandatory={true}
                    handleNumberChange={(_, __, phoneObj) =>
                      handlePhoneChange(phoneObj, setFieldValue)
                    }
                    onBlur={handleBlur}
                    name={CONTACT_DETAILS_FIELDS.PHONE}
                  />
                  <IonGrid className="text_left">
                    <IonText className="danger_light_text input_error">
                      {touched.phone && (errors.phone || phoneError)}
                    </IonText>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid className="navigation_main_container">
              <IonGrid className="navigation_container">
                <Button
                  label={BUTTON_LABELS.BACK}
                  onClick={() => handleBack(values)}
                  classes="button navigation outline"
                />
                <Button
                  label={BUTTON_LABELS.NEXT}
                  onClick={handleSubmit}
                  classes={`button ${
                    !isValid || phoneError || emailError ? "navigation_disabled" : "navigation"
                  }`}
                  disabled={!isValid || Boolean(phoneError) || Boolean(emailError)}
                />
              </IonGrid>
            </IonGrid>
          </IonGrid>
        )}
      </Formik>
    </IonGrid>
  );
};

export default StepContactDetails;
