import React, { useEffect } from "react";
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import "./ValuationReportContainer.scss";
import AchievingPercentage from "@components/Report/AchievingPercentage/AchievingPercentage";
import PeakActivity from "@components/Report/PeakActivity/PeakActivity";
import AddressBanner from "@components/AddressBanner/AddressBanner";
import ListPropertyBanner from "@components/ListPropertyBanner/ListPropertyBanner";
import PropertySummary from "@components/Report/PropertySummary/PropertySummary";
import TenantDatabase from "@components/Report/TenantDatabase/TenantDatabase";
import TenantProfileForArea from "@components/Report/TenantProfileForArea/TenantProfileForArea";
import RentalValue from "@components/Report/RentalValue/RentalValue";
import RentRange from "@components/Report/RentRange/RentRange";
import { useAppDispatch, useAppSelector } from "@hooks";
import { downloadReport, getReport } from "@store/report/reportSlice";
import { TESTIMONIALS } from "@constants/report.constants";
import MarketAnalysis from "@components/Report/MarketAnalysis/MarketAnalysis";
import PropertySits from "@components/Report/PropertySits/PropertySits";
import { MixpanelEventNames } from "@utils/mixpanel/events";
import Mixpanel from "@utils/mixpanel";
import Testimonials from "@components/Report/Testimonials/Testimonials";
import LoadingContainer from "./LoadingContainer";
import Footer from "@components/Footer/Footer";
import { generateValuationPayload, validateValuationPayload } from "@utils/helpers/data.helper";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReportPayload } from "@store/report/types";
import toast from "react-hot-toast";
import { populateStateFromQueryStepsData } from "@utils/helpers/populate-state-from-query.helper";

// import Maps from "@hoc/Maps/Maps";
// import LetSection from "@components/Report/LetSection/LetSection";
// import { LET_SECTION_TYPE_ENUM } from "@constants/report.constants";

const ValuationReportContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loading } = useAppSelector((state) => state.report);
  const formData = useAppSelector((state) => state.valuation);
  const [searchParams, setSearchParams] = useSearchParams();

  const valuation_id = searchParams.get("valuation_id");
  const firstName = searchParams.get("first_name");
  const hideHeaderFooter: string | null = searchParams.get("hideHeaderFooter");

  const getReportFromPost = async () => {
    const payload: ReportPayload = generateValuationPayload(formData);
    const updatedPayload = { ...payload, navigate };
    if (validateValuationPayload(updatedPayload)) {
      const res = await dispatch(downloadReport(updatedPayload));
      if (res.payload.err) navigate("/home");
      if (res.payload.navigateTo) navigate(res.payload.navigateTo, { replace: true });
      else {
        const valuationId = res.payload?.valuation_link?.split("valuation_id=")[1].split("&")[0];
        const firstName = res.payload?.valuation_link?.split("first_name=")[1];
        if (valuationId && firstName)
          setSearchParams({
            valuation_id: valuationId,
            first_name: firstName
          });
        else navigate("/home");
      }
    }
  };

  const getReportFromGet = async () => {
    if (valuation_id && firstName) {
      try {
        const decrypted_id = atob(valuation_id);
        const res = await dispatch(getReport({ valuationId: decrypted_id }));
        if ((res as any).error) {
          toast.error("API Error Please try again");
          navigate("/home");
        }
      } catch (err) {
        console.log("Error", err);
        toast.error("API Error Please try again");
        navigate("/home");
      }
    } else {
      navigate("/home");
    }
  };

  useEffect(() => {
    if (!(valuation_id && firstName))
      populateStateFromQueryStepsData(searchParams, dispatch, navigate);
  }, []);

  useEffect(() => {
    if (valuation_id && firstName) {
      getReportFromGet();
      Mixpanel(MixpanelEventNames.view_property_report_page);
    }
  }, []);

  useEffect(() => {
    if (formData.propertyType) {
      getReportFromPost();
      Mixpanel(MixpanelEventNames.view_property_report_page);
    }
  }, [formData.propertyType]);

  if (loading) {
    return <LoadingContainer />;
  }

  return (
    <>
      <IonGrid>
        <IonRow className="hero_header workContainer">
          <IonCol size="12" sizeLg="8.5" sizeMd="7.5" className="left_col">
            <AddressBanner />
          </IonCol>
          <IonCol size="12" sizeLg="3.5" sizeMd="4.5" className="right_col">
            <ListPropertyBanner />
          </IonCol>
        </IonRow>
        <IonRow className="result_container">
          <IonCol size="12">
            <IonGrid className="cards_list">
              <IonRow className="contentContainer main_container">
                {/* Rental Value */}
                <IonCol className="left_col" size="12" sizeLg="6">
                  <IonGrid className="card">
                    <RentalValue />
                  </IonGrid>
                </IonCol>
                {/* Rental Range */}
                <IonCol className="right_col" size="12" sizeLg="6">
                  <IonGrid className="card">
                    <RentRange />
                  </IonGrid>
                </IonCol>
              </IonRow>
              <IonRow className="contentContainer main_container">
                {/* Tenant Profile */}
                <IonCol className="left_col" size="12" sizeLg="6">
                  <IonGrid className="card">
                    <TenantProfileForArea />
                  </IonGrid>
                </IonCol>
                {/* Tenant Database */}
                <IonCol className="right_col" size="12" sizeLg="6">
                  <IonGrid className="card">
                    <TenantDatabase />
                  </IonGrid>
                </IonCol>
              </IonRow>
              <IonRow className="contentContainer main_container">
                {/* Peak Activity */}
                <IonCol className="left_col" size="12" sizeLg="6">
                  <IonGrid className="card">
                    <PeakActivity />
                  </IonGrid>
                </IonCol>
                {/* Property Summary */}
                <IonCol className="right_col" size="12" sizeLg="6">
                  <IonGrid className="card">
                    <PropertySummary />
                  </IonGrid>
                </IonCol>
              </IonRow>
              <IonRow className="contentContainer main_container">
                {/* Property sits in the market */}
                <IonCol className="left_col" size="12" sizeLg="6">
                  <IonGrid className="card">
                    <PropertySits />
                  </IonGrid>
                </IonCol>
                {/* properties near you */}
                <IonCol className="right_col" size="12" sizeLg="6">
                  <IonGrid className="card">
                    <AchievingPercentage />
                  </IonGrid>
                </IonCol>
              </IonRow>
              <IonRow className="contentContainer main_container">
                {/* Market Analysis */}
                <IonCol className="full_col" size="12">
                  <IonGrid className="card">
                    <MarketAnalysis />
                  </IonGrid>
                </IonCol>
              </IonRow>
              <IonRow className="testimonial_container">
                <IonText className="testimonials-title">{TESTIMONIALS}</IonText>
                <IonCol className="" size="12">
                  <Testimonials />
                </IonCol>
              </IonRow>
              {/* currently for let */}
              {/* <IonRow className=" mb_0">
              <IonCol className="" size="12" sizeLg="6">
              <IonGrid className="map_card map_wrapper">
              <Maps />
              </IonGrid>
              </IonCol>
              <IonCol className="" size="12" sizeLg="6">
              <IonGrid className="map_card card_right">
              <LetSection type={LET_SECTION_TYPE_ENUM.CURRENTLY} />
              </IonGrid>
              </IonCol>
            </IonRow> */}
              {/* Recently let */}
              {/* <IonRow className="">
              <IonCol className="" size="12" sizeLg="6">
              <IonGrid className="map_card card_left">
              <LetSection type={LET_SECTION_TYPE_ENUM.RECENTLY} />
              </IonGrid>
              </IonCol>
              <IonCol className="" size="12" sizeLg="6">
              <IonGrid className="map_card map_wrapper">
              <Maps />
              </IonGrid>
              </IonCol>
            </IonRow> */}
              {/* testimonial */}
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid className="footer_wrapper">
        <IonGrid className="workContainer report_footer">
          {hideHeaderFooter !== "true" && <Footer />}
        </IonGrid>
      </IonGrid>
    </>
  );
};

export default ValuationReportContainer;
