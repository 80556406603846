import React, { useEffect, useState } from "react";
import Button from "@components/Button/Button";
import { BUTTON_LABELS, STEPS } from "@constants/home.constants";
import { FORM_TITLE, OPTIONS_TYPES } from "@constants/options.constant";
import { OPTION_OBJ_TYPE } from "@constants/propertyType.interface";
import { useAppSelector } from "@hooks/redux";
import { IonGrid, IonText } from "@ionic/react";
import {
  resetPropertySubTypeValue,
  resetPropertyTypeValue,
  setPropertySubTypeValue,
  setPropertyTypeValue
} from "@store/valuation/valuationSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./propertyType.scss";
import { MixpanelEventNames } from "@utils/mixpanel/events";
import { JOURNEY_NAME, NEXT_BUTTON_CLICK_TYPE, PAGE_VARIANT } from "@utils/mixpanel/constants";
import Mixpanel from "@utils/mixpanel";
import { isIOS } from "react-device-detect";
import { useWindowDimensions } from "@hooks/useWindowDimensions";
import { populateStateFromQueryStepsData } from "@utils/helpers/populate-state-from-query.helper";
import { addPreviousStep, removeLastStep } from "@utils/helpers/add-query-step.helper";

const PropertyTypeOption = () => {
  const formData = useAppSelector((state) => state.valuation);
  const [selectedOption, setSelectedOption] = useState({
    propertyType: formData.propertyType || "",
    propertyTypeId: formData.propertySubType || 0
  });

  const { isMobile } = useWindowDimensions();

  const [searchParams, setSearchParams] = useSearchParams();
  const step: string | null = searchParams.get("step");
  const propertyType = step;
  const options = propertyType && OPTIONS_TYPES[propertyType];
  const title = propertyType && FORM_TITLE[propertyType];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    populateStateFromQueryStepsData(searchParams, dispatch, navigate);
  }, []);

  useEffect(() => {
    if (isIOS) {
      document.getElementById("propertyType_form")?.scrollIntoView(true);
    } else if (isMobile) {
      document
        .getElementById("propertyType_form")
        ?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, [options]);

  const getMixpanelPropertyTypeData = (propertyType: string | undefined, buttonPressed: string) => {
    return {
      "Journey Name": JOURNEY_NAME.owner,
      "Button Pressed": buttonPressed,
      "Page Variant": PAGE_VARIANT.A,
      "Building Type": propertyType ? propertyType : 0
    };
  };

  const getMixpanelPropertyKindData = (
    propertyTypeId: number | undefined,
    buttonPressed: string
  ) => {
    return {
      "Journey Name": JOURNEY_NAME.owner,
      "Building Type": selectedOption.propertyType,
      "Building Kind":
        propertyTypeId && options ? options.find((x) => x.value === propertyTypeId)?.label : 0,
      "Button Pressed": buttonPressed,
      "Page Variant": PAGE_VARIANT.A
    };
  };

  const generateMixPanelData = (
    propertyType: string, // type value
    propertyTypeId: number | undefined, // kind value
    buttonPressed: string
  ) => {
    if (propertyType && step && ![STEPS.HOUSE, STEPS.FLAT, STEPS.BUNGALOW].includes(step)) {
      // selected TYPE going front to kind - Logging TYPE
      Mixpanel(
        MixpanelEventNames.pl_valuation_building_type_selection,
        getMixpanelPropertyTypeData(propertyType, buttonPressed)
      );
      return;
    }
    if (propertyTypeId && step && [STEPS.HOUSE, STEPS.FLAT, STEPS.BUNGALOW].includes(step)) {
      // selected KIND going front - Logging KIND
      Mixpanel(
        MixpanelEventNames.pl_valuation_building_kind_selection,
        getMixpanelPropertyKindData(propertyTypeId, buttonPressed)
      );
      return;
    }
    if (!propertyType && buttonPressed === NEXT_BUTTON_CLICK_TYPE.back) {
      // not selected Type going back to address - Logging Type with undefined
      Mixpanel(
        MixpanelEventNames.pl_valuation_building_type_selection,
        getMixpanelPropertyTypeData(undefined, buttonPressed)
      );
      return;
    }
    if (!propertyTypeId && buttonPressed === NEXT_BUTTON_CLICK_TYPE.back) {
      // not selected kind going back to type - Logging kind with undefined
      Mixpanel(
        MixpanelEventNames.pl_valuation_building_kind_selection,
        getMixpanelPropertyKindData(undefined, buttonPressed)
      );
      return;
    }
    if (
      buttonPressed === NEXT_BUTTON_CLICK_TYPE.back &&
      propertyTypeId &&
      step &&
      [STEPS.HOUSE, STEPS.FLAT, STEPS.BUNGALOW].includes(step)
    ) {
      // selected KIND going back to Type - Logging KIND
      Mixpanel(
        MixpanelEventNames.pl_valuation_building_kind_selection,
        getMixpanelPropertyKindData(propertyTypeId, buttonPressed)
      );
      return;
    }
    if (
      buttonPressed === NEXT_BUTTON_CLICK_TYPE.back &&
      propertyType &&
      step &&
      ![STEPS.HOUSE, STEPS.FLAT, STEPS.BUNGALOW].includes(step)
    ) {
      // selected Type going back - Logging TYPE
      Mixpanel(
        MixpanelEventNames.pl_valuation_building_type_selection,
        getMixpanelPropertyTypeData(propertyType, buttonPressed)
      );
      return;
    }
  };

  const onOptionClick = (propertyType: string, propertyTypeId: number | undefined) => {
    setSelectedOption({ propertyType: propertyType, propertyTypeId: propertyTypeId || 0 });

    generateMixPanelData(propertyType, propertyTypeId, NEXT_BUTTON_CLICK_TYPE.automatic);

    // switch to next url
    onSubmit(propertyType, propertyTypeId || 0, false);
  };

  const isPropertyTypeActive = propertyType === STEPS.PROPERTY_TYPE;
  const isValueSelected = () => {
    if (isPropertyTypeActive) {
      return Boolean(selectedOption.propertyType);
    }
    return Boolean(selectedOption.propertyTypeId);
  };

  const handleBack = () => {
    if (isPropertyTypeActive) {
      searchParams.delete(STEPS.PROPERTY_TYPE);
      searchParams.set("step", STEPS.ADDRESS);
      searchParams.set("previousSteps", removeLastStep(STEPS.PROPERTY_TYPE, searchParams));
      dispatch(resetPropertyTypeValue());
      setSelectedOption({ ...selectedOption, propertyType: "" });
    } else {
      searchParams.delete(STEPS.PROPERTY_SUB_TYPE);
      searchParams.set("step", STEPS.PROPERTY_TYPE);
      searchParams.set("previousSteps", removeLastStep(STEPS.PROPERTY_SUB_TYPE, searchParams));
      dispatch(resetPropertySubTypeValue());
      setSelectedOption({ ...selectedOption, propertyTypeId: 0 });
    }

    generateMixPanelData(
      selectedOption.propertyType,
      selectedOption.propertyTypeId,
      NEXT_BUTTON_CLICK_TYPE.back
    );
    setSearchParams(searchParams);
  };

  const activeValue = (option: OPTION_OBJ_TYPE) => {
    if (isPropertyTypeActive) {
      return option.label === selectedOption.propertyType;
    }
    return option?.value === selectedOption.propertyTypeId;
  };

  const onSubmit = (propertyType: string, propertyTypeId: number, isNext = true) => {
    if (isPropertyTypeActive) {
      dispatch(setPropertyTypeValue(propertyType));
      const encryptedPropertyType = btoa(propertyType);
      searchParams.set("previousSteps", addPreviousStep(STEPS.PROPERTY_TYPE, searchParams));
      searchParams.set(STEPS.PROPERTY_TYPE, encryptedPropertyType);
      searchParams.set("step", propertyType);
    } else {
      // subtype property selection
      dispatch(setPropertySubTypeValue(propertyTypeId));
      const encryptedPropertySubtype = btoa(JSON.stringify(propertyTypeId));
      searchParams.set("previousSteps", addPreviousStep(STEPS.PROPERTY_SUB_TYPE, searchParams));
      searchParams.set(STEPS.PROPERTY_SUB_TYPE, encryptedPropertySubtype);
      searchParams.set("step", STEPS.BEDROOMS);
    }
    if (isNext) {
      generateMixPanelData(
        selectedOption.propertyType,
        selectedOption.propertyTypeId,
        NEXT_BUTTON_CLICK_TYPE.manual
      );
    }
    setSearchParams(searchParams);
  };

  return (
    <IonGrid className="propertyType">
      <IonGrid id="propertyType_form">
        <IonGrid className="title-container">
          <IonText className="title">{title}</IonText>
        </IonGrid>
        <IonGrid className={`flex ${!isPropertyTypeActive ? "button-container" : ""}`}>
          {options &&
            options.map((option) => {
              return (
                <Button
                  key={option.label}
                  classes={`form_button ${activeValue(option) ? "selectedButton" : ""}`}
                  label={option.label}
                  onClick={() => onOptionClick(option.label, option.value)}
                />
              );
            })}
        </IonGrid>
        <IonGrid className="navigation_main_container">
          <IonGrid className="navigation_container">
            <Button
              label={BUTTON_LABELS.BACK}
              classes={`navigation outline`}
              onClick={handleBack}
            />
            <Button
              label={BUTTON_LABELS.NEXT}
              classes={`${isValueSelected() ? "navigation" : "navigation_disabled"}`}
              disabled={!isValueSelected()}
              onClick={() =>
                onSubmit(selectedOption.propertyType, selectedOption.propertyTypeId, true)
              }
            />
          </IonGrid>
        </IonGrid>
      </IonGrid>
    </IonGrid>
  );
};

export default PropertyTypeOption;
