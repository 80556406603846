// imports
import React from "react";
import { IonGrid, IonRow, IonText } from "@ionic/react";
import Mixpanel from "@utils/mixpanel";
import { useSearchParams } from "react-router-dom";

// local imports
import { MixpanelEventNames } from "@utils/mixpanel/events";
import { FOOTER_COPYRIGHT, FOOTER_LINKS, FOOTER_LIST } from "@constants/footer.constant";
import { JOURNEY_NAME, PAGE_VARIANT } from "@utils/mixpanel/constants";
import { STEPS } from "@constants/home.constants";

// styles
import "./Footer.scss";

// interfaces
interface IFooterItem {
  Terms: string;
  "Privacy Policy": string;
}

const Footer = () => {
  const [searchParams] = useSearchParams();
  const step: string | null = searchParams.get("step");

  const handleFooterTextClick = (footerSection: keyof IFooterItem) => {
    const mxEventName = MixpanelEventNames.pl_valuation_lower_menu_links;
    const mxPayload = {
      "Page Variant": PAGE_VARIANT.A,
      "Journey Name": JOURNEY_NAME.owner,
      "Page Name": step ? step : STEPS.ADDRESS,
      "Link Name": footerSection,
      "Target URL": FOOTER_LINKS[footerSection]
    };
    Mixpanel(mxEventName, mxPayload);
    window.open(FOOTER_LINKS[footerSection]);
  };

  return (
    <IonGrid className="footer_container">
      <IonRow className="footer_list">
        <IonText
          className="cursor"
          onClick={() => handleFooterTextClick(FOOTER_LIST.TERMS as keyof IFooterItem)}
        >
          {FOOTER_LIST.TERMS}
        </IonText>
        <IonText
          className="cursor"
          onClick={() => handleFooterTextClick(FOOTER_LIST.PRIVACY_POLICY as keyof IFooterItem)}
        >
          {FOOTER_LIST.PRIVACY_POLICY}
        </IonText>
      </IonRow>
      <IonText className="copyright_text">{FOOTER_COPYRIGHT}</IonText>
    </IonGrid>
  );
};

export default Footer;
