// imports
import React, { useEffect } from "react";
import { IonGrid, IonRow, IonCol, IonText } from "@ionic/react";

// local imports
import AddressBanner from "@components/AddressBanner/AddressBanner";
import Footer from "@components/Footer/Footer";
import ListPropertyBanner from "@components/ListPropertyBanner/ListPropertyBanner";
import { HEADER_LIST_LINKS } from "@constants/header.constant";

// styles
import "./AreaNotCovered.scss";
import { useAppSelector } from "@hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { validateValuationPayload } from "@utils/helpers/data.helper";

export const AreaNotCovered = () => {
  const navigate = useNavigate();
  const formData = useAppSelector((state) => state.valuation);
  const [searchParams] = useSearchParams();
  const hideHeaderFooter: string | null = searchParams.get("hideHeaderFooter");

  const payload = {
    property: {
      postcoderAddress: formData.address,
      propertyType: formData.propertySubType,
      bedrooms: formData.bedroomDetails.value,
      propertyAvailability: formData.propertyAvailabilityDetails.value,
      isInLondon: formData.isInLondon
    },
    lead: {
      firstName: formData.contactDetails?.firstName,
      lastName: formData.contactDetails?.surname,
      email: formData.contactDetails?.email,
      countryCode: formData.contactDetails?.countryCode,
      phone: formData.contactDetails?.phone
    }
  };

  useEffect(() => {
    const updatedPayload = { ...payload, navigate };
    if (!validateValuationPayload(updatedPayload)) {
      navigate("/home");
    }
  }, []);

  return (
    <IonGrid className="areaNotCovered">
      <IonGrid>
        <IonRow className="hero_header workContainer">
          <IonCol size="12" sizeLg="8.5" sizeMd="7.5" className="left_col">
            <AddressBanner />
          </IonCol>
          <IonCol size="12" sizeLg="3.5" sizeMd="4.5" className="right_col">
            <ListPropertyBanner />
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonRow className="anc_content contentContainer">
        <IonCol size="12" sizeMd="8">
          <IonGrid className="">
            <IonText className="anc_heading">
              We’ll let you know the moment we reach your area.
            </IonText>
            <IonText className="anc_para">
              Meanwhile, you can still add your property and take full advantage of our Free
              Management and Compliance tools. These include:
              <ul>
                <li>
                  Manage your tenancies, and securely communicate with your tenants directly through
                  our messaging platform whilst keeping all this in one place for your future
                  records.
                </li>
                <li>
                  Tenants can report any problems they are experiencing through our ’Report a
                  Repair’ tool.
                </li>
                <li>
                  Store all your documents in our Document Vault, including your property
                  certifications and receive notifications when any are due to expire. Never miss a
                  gas safety or electrical inspection deadline ever again!
                </li>
                <li>
                  Access our nationwide service to order Gas Safety Certificates, EPCs or Electrical
                  Inspections with a click of a button.
                </li>
              </ul>
            </IonText>
            <IonGrid className="anc_registered_block">
              <IonText className="anc_reg_text">Click here to get started for free</IonText>
              <IonGrid
                className="anc_reg_btn"
                onClick={() => window.open(`${HEADER_LIST_LINKS.SIGN_IN}`)}
              >
                Register here
              </IonGrid>
            </IonGrid>
          </IonGrid>
        </IonCol>
      </IonRow>
      <IonGrid className="footer_wrapper">
        <IonGrid className="workContainer report_footer">
          {hideHeaderFooter !== "true" && <Footer />}
        </IonGrid>
      </IonGrid>
    </IonGrid>
  );
};
