// package imports
import React, { useState, useEffect, useRef } from "react";
import { IonCol, IonLabel, IonRow, IonGrid, IonText, IonInput, IonImg } from "@ionic/react";

// local imports
import "./PhoneInput.scss";
import { InputChangeEventDetail } from "@ionic/core";
import { isValidPhoneNumber } from "react-phone-number-input";
import OutsideAlerter from "@hoc/OutsideAlerter/OutsideAlerter";
import InputComp from "@components/InputComp/InputComp";
import { getImagePath } from "@utils/helpers/media.helper";

// interfaces
interface country {
  id: number;
  iso: string;
  name: string;
  nicename: string;
  iso3: string | null;
  numcode: number | null;
  phonecode: number;
}

interface phone {
  country: country | null | undefined;
  phone: string | null | undefined;
}

export interface phoneObjInterface {
  country: country | null | undefined;
  phone: string | null | undefined;
  error: string;
  contactNo: string;
}

interface returnValue {
  country: country | null | undefined;
  phone: string | null | undefined;
  error: string;
  contactNo: string | null | undefined;
}

interface InputProps {
  classes?: string;
  styleProps?: Record<string, unknown>;
  disabled?: boolean;
  onClick?: () => void;
  onChange?: (val: returnValue) => void;
  showLabel?: boolean;
  labelName?: string;
  isMandatory?: boolean;
  placeholder?: string;
  min?: number;
  type?: string;
  value?: string;
  countryCodeValue: number;
  countryCodeList: Array<country>;
  handleNumberChange?: (contact: string, phoneNum: string, phoneObj: any) => void;
  addStyle?: boolean;
  onBlur?: (e: any) => void;
  name?: string;
}

const PhoneInput = ({
  classes,
  styleProps,
  disabled,
  showLabel = false,
  labelName = "Phone",
  placeholder = "XX-XXX-XX-XX",
  value,
  isMandatory = false,
  countryCodeValue,
  countryCodeList,
  handleNumberChange,
  addStyle,
  onBlur,
  name
}: InputProps) => {
  const [showCountryCodeList, setShowCountryCodeList] = useState<boolean>(false);
  const [filteredList, setFilteredList] = useState<Array<country>>(countryCodeList);

  const [selectedCountry, setSelectedCountry] = useState<country>();

  //to restrict wheel scroll on Number type textField
  const textFieldRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e: any) => e.preventDefault();
    const WheelScrollRestrict: any = textFieldRef.current;
    WheelScrollRestrict.addEventListener("wheel", handleWheel);

    return () => {
      WheelScrollRestrict.removeEventListener("wheel", handleWheel);
    };
  }, []);

  useEffect(() => {
    if (!showCountryCodeList) {
      setFilteredList(countryCodeList);
    }
  }, [showCountryCodeList]);

  const inputOptionProps = {
    showLabel: false,
    showUpperText: false,
    showLowerStatus: false
  };

  const inputStyleProps = {
    paddingRight: "0px"
  };

  useEffect(() => {
    const code = countryCodeValue || 44; // Select 44 by default
    const presetCountry = countryCodeList.find((item) => item.phonecode === code);
    setSelectedCountry(presetCountry);
  }, []);

  const handleCountryCodeSearch = (key: string | null | undefined) => {
    if (key) {
      const filterList = countryCodeList.filter(
        (country: { phonecode: number; nicename: string }) =>
          country.nicename.toLowerCase().includes(key.toLowerCase()) ||
          country.phonecode.toString().includes(key)
      );
      setFilteredList(filterList);
    }
  };

  const validateMobile = (code: number, phone: string | null | undefined) => {
    const phNo = phone || "";
    if (isValidPhoneNumber(`+${code}${phNo}`)) {
      return true;
    } else return false;
  };

  const handleChange = ({ country, phone }: phone) => {
    if (phone) phone = phone.split(" ").join("");
    let error = "";
    if (country && !validateMobile(country.phonecode, phone)) {
      error = "Invalid mobile number";
    } else {
      error = "";
    }
    const phoneObj: phoneObjInterface = {
      country,
      phone,
      error,
      contactNo: `+${country?.phonecode}${phone}`
    };
    handleNumberChange && handleNumberChange("contact", "phoneNum", phoneObj);
  };

  const handleSelectCountryCode = (country: country) => {
    setSelectedCountry(country);
    setShowCountryCodeList(false);
    setFilteredList(countryCodeList);
    handleChange({ country, phone: value });
  };

  const countryRecord = (country: country) => {
    return (
      <IonRow
        key={country.iso}
        className="country-code-row"
        onClick={() => handleSelectCountryCode(country)}
      >
        <IonGrid className="country-code-text">+{country.phonecode}</IonGrid>
        <IonGrid className="country-name-text">{country.nicename}</IonGrid>
      </IonRow>
    );
  };

  const renderCountryCodeList = () => {
    let list = [...filteredList];
    const selectedCountryIndex = list.findIndex(
      (item) => item.phonecode === selectedCountry?.phonecode
    );

    if (selectedCountry && selectedCountryIndex > 0) {
      list = list.filter((item) => item.phonecode !== selectedCountry?.phonecode);
      list = [selectedCountry, ...list];
    }

    return list.length ? (
      <>{list.map((country: country) => countryRecord(country))}</>
    ) : (
      <IonRow>No match found!</IonRow>
    );
  };

  const keyPressFunc = (event: {
    keyCode: number;
    charCode: number;
    preventDefault: () => void;
  }) => {
    // restricting the negative and positive values ('-', '+')
    if (event.keyCode === 8 || (event.charCode >= 48 && event.charCode <= 57)) {
      console.log(null);
    } else {
      event.preventDefault();
    }
  };

  return (
    <IonGrid className={`phone-input-comp ${classes}`} style={styleProps}>
      {showLabel && (
        <IonLabel class="label">
          {" "}
          {labelName}
          <IonText className="inputs_mandatory">{isMandatory ? "*" : "\u00A0"}</IonText>
        </IonLabel>
      )}

      <IonRow className={`${addStyle} phone-number-input`}>
        <IonCol size="12" className="">
          <IonGrid
            className={`phone-input-wrapper ion-align-items-center ${
              addStyle ? "code-country" : ""
            }`}
          >
            <IonGrid
              className="country-code ion-align-items-center"
              onClick={() => setShowCountryCodeList(!showCountryCodeList)}
            >
              <IonText className={`country-code-dropdown-text`}>
                {" "}
                +{selectedCountry?.phonecode}{" "}
              </IonText>
              <img src={getImagePath(`svg/caret-down.svg`)} alt="arrow" width="8px" />
            </IonGrid>
            <InputComp
              styleProps={inputStyleProps}
              classes="input-field phone-input"
              disabled={disabled}
              placeholder={placeholder}
              type="tel"
              isFullWidth={true}
              isMandatory={isMandatory}
              defaultValue={value}
              onBlur={onBlur}
              name={name}
              value={value}
              inputMode="numeric"
              options={inputOptionProps}
              onChange={(value) =>
                handleChange({
                  country: selectedCountry,
                  phone: value
                })
              }
              inputRef={textFieldRef}
              keyPress={keyPressFunc}
            />
          </IonGrid>
        </IonCol>
      </IonRow>
      {showCountryCodeList && (
        <OutsideAlerter
          onOutsideClick={() => {
            setShowCountryCodeList(false);
          }}
        >
          <IonGrid className={`country-search-wrapper ${addStyle ? "code-pos" : ""}`}>
            <IonCol size="12" className="country-search text_left">
              <IonInput
                placeholder="Search"
                onIonChange={(e: CustomEvent<InputChangeEventDetail>) =>
                  handleCountryCodeSearch(e.detail.value)
                }
              >
                <IonImg src={getImagePath(`svg/search-icon.svg`)} alt="arrow" />
              </IonInput>
            </IonCol>
            <IonGrid className="dropdown-scroll">{renderCountryCodeList()}</IonGrid>
          </IonGrid>
        </OutsideAlerter>
      )}
    </IonGrid>
  );
};

export default PhoneInput;
